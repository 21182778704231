.numberCircle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 8px;
    background: black;
    color: white;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    flex-shrink: 0;
}

.ai {
    height: 200px;
    display: block;
    margin: 50px auto;
}