.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}

.video_background {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1000;
}