.model_icon {
    display: block;
    margin: 40px auto;
    height: 60px;
}

.model_name {
    width: auto;
    min-width: 0;
    display: table;
    margin: auto;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px;
    background: #1f263e;
    color: white;
}